import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const PreviewCompatibleImage = ({ imageInfo, imageStyle }) => {
    const { alt = '', childImageSharp, image } = imageInfo;

    if (!!image && !!image.childImageSharp) {
        return (
            <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                style={imageStyle}
                alt={alt}
            />
        );
    }
    if (childImageSharp) {
        return (
            <GatsbyImage
                image={childImageSharp.gatsbyImageData}
                style={imageStyle}
                alt={alt}
            />
        );
        // for Netlify CMS
    }
    if (image) {
        return <img style={{ imageStyle }} src={image} alt={alt} />;
    }
    return null;
};

PreviewCompatibleImage.propTypes = {
    imageInfo: PropTypes.shape({
        alt: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
            .isRequired,
    }).isRequired,
};

export default PreviewCompatibleImage;
